<template>

  <div class="workflow__details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ articleCategory.name }}
            </h1>
            <p class="log_info">
              Created by {{ articleCategory.createdBy ? articleCategory.createdBy.name : '' }} on {{ dateFormatWithTime(articleCategory.createdAt) }} <br>Last updated on {{ dateFormatWithTime(articleCategory.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <b-button
          variant="light"
          class="text-primary"
          :to="{ name: 'app-article-categories-edit', params: { id: $route.params.id } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For Nirvana SG</strong> This is the control panel where administrators can edit the content for the customer-facing mobile app.</span>
      </div>
    </b-alert>
    <b-card
      class="mb-3 px-3 py-2 calllog-details"
    >
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230">
              Category
            </b-th>
            <b-td class="mt-50">
              {{ articleCategory.name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Status
            </b-th>
            <b-td class="mt-50">
              <span :class="articleCategory.status == 'active' ? 'text-success text-capitalize' : 'text-capitalize'">
                {{ articleCategory.status }}
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card
      header-tag="header"
    >
      <template #header>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="flat-primary"
          class="primary-button-with-background mr-3"
        >
          <feather-icon
            icon="HelpCircleIcon"
            class=""
            size="20"
          />
        </b-button>
        <h3 class="align-middle mr-auto">
          All articles under this category
        </h3>
      </template>
      <b-row>
        <b-col
          v-if="catArticles.length > 0"
          cols="md-12"
        >
          <b-list-group
            v-for="(article, key) in catArticles"
            :key="key"
            flush
            class="border-bottom"
          >
            <b-list-group-item class="d-flex justify-content-between px-0">
              <div
                class="d-flex flex-sm-row flex-column align-items-start hover-td"
              >
                <div
                  class="mr-3"
                >
                  {{ article.title }}
                </div>
                <div
                  class="action_button wf-action-button article__action-btn-grp"
                >
                  <b-button
                    v-if="canViewThisAction('delete', 'Article')"
                    v-b-tooltip.hover.top="'Delete'"
                    variant="flat-primary"
                    @click="deleteEntity(article._id)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    v-if="canViewThisAction('update', 'Article')"
                    v-b-tooltip.hover.top="'Edit'"
                    variant="flat-primary"
                    :to="{ name: 'app-articles-edit', params: { id: article._id }, query: { category: $route.params.id } }"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    v-show="key != 0"
                    v-b-tooltip.hover.top="'Move up'"
                    variant="flat-primary"
                    @click="moveTask(key, key-1)"
                  >
                    <feather-icon
                      icon="ArrowUpIcon"
                      class="mr-50"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    v-show="key != (catArticles.length - 1)"
                    v-b-tooltip.hover.top="'Move down'"
                    variant="flat-primary"
                    @click="moveTask(key, key+1)"
                  >
                    <feather-icon
                      icon="ArrowDownIcon"
                      class="mr-50"
                      size="16"
                    />
                  </b-button>
                </div>
              </div>
              <b-badge
                :variant="`light-${resolveArticleStatusVariant(article.status)}`"
                class="text-capitalize"
              >
                {{ article.status }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col v-else>
          <div class="empty_block">
            <h4>No article under this article category yet</h4>
            <b-button
              type="button"
              variant="flat-primary"
              :to="{ name: 'app-articles-create', query: { category: $route.params.id } }"
            >
              <span class="align-middle">Add New Article</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBTooltip, BAlert, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import 'array.prototype.move'
import store from '@/store/index'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,

    BAlert,
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      topSpacing: 135,
      canViewThisAction,
      articleCategory: {},
      catArticles: [],
    }
  },
  created() {
    this.getArticleCategory()
    // this.getArticles()
  },

  methods: {
    resolveArticleStatusVariant(status) {
      if (status === 'active') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    },

    getArticleCategory() {
      this.$http.get(`customer/article-categories/${this.$route.params.id}/show`)
        .then(response => {
          this.articleCategory = response.data || {}
          this.catArticles = response.data.articles || []
          store.commit('breadcrumb/REMOVE_BREADCRUMB')
          const newMeta = {
            active: true,
            text: `${this.articleCategory.name}`,
          }
          store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    getArticles() {
      this.$http.get(`customer/article-categories/${this.$route.params.id}/all-articles`)
        .then(response => {
          this.catArticles = response.data.catArticles || []
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    deleteEntity(id) {
      this.$swal({
        title: 'Delete this Article?',
        html: 'Are you sure you want to delete this article? This cannot be undone..',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete-yellow.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.delete(`customer/articles/${id}/delete`)
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message ?? '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                this.getArticles()
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    moveTask(from, to) {
      this.catArticles.move(from, to)

      const articlesPriorityArray = []
      this.catArticles.forEach(taskList => {
        articlesPriorityArray.push(taskList._id)
      })
      const formData = new FormData()

      formData.append('articleCategory', this.$route.params.id)
      formData.append('articles', JSON.stringify(articlesPriorityArray))

      this.$http.patch('customer/article-categories/update/order/article-list', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.article__action-btn-grp{
     .btn{
       padding: 0;
       &:hover{
         background-color: transparent;
       }
     }
}
</style>
